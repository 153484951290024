<template>
  <div>
    <!--  标题, 注册  -->
    <HomeHeader @alter:notice="handleNoticeName"></HomeHeader>
    <!--  tab页  -->
    <TypeNav @alter:value="handleValueUpdate"></TypeNav>
    <!--  轮播图  -->
    <!-- <ListContainer v-if="containerFlag"></ListContainer> -->
    <!--  今日开标, 今日公告  -->
    <MoreList ref="moreListRef"></MoreList>
    <!--  招标公示, 个人中标  -->
    <!-- <BiddingList></BiddingList> -->
    <!--  结尾  -->
    <HomeFloor></HomeFloor>
  </div>
</template>


<script>
import HomeHeader from "@/components/home/HomeHeader.vue";
import TypeNav from "@/components/home/TypeNav.vue";
import ListContainer from "@/components/home/ListContainer.vue";
import MoreList from "@/components/home/MoreList.vue";
import BiddingList from "@/components/home/BiddingList.vue";
import HomeFloor from "@/components/home/HomeFloor.vue";

export default {
  name: 'homeView',
  components: {
    HomeHeader,
    TypeNav,
    ListContainer,
    MoreList,
    BiddingList,
    HomeFloor
  },
  data() {
    return {
      containerFlag: true
    }
  },
  methods: {
    handleValueUpdate(value) {
      this.containerFlag = value
    },
    handleNoticeName(value){
      let params = {};
      params.uTitle = value
      this.$refs.moreListRef.conditionalQuery(params);
    }
  }
}
</script>
