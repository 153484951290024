import request from '@/utils/request'

// 查询合格供应商列表
export function listSupplier(query) {
    return request({
        url: '/wms/supplier/list',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function listSupplier1(query) {
    return request({
        url: '/wms/supplier/noSupplierList',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function listSupplier2(query) {
    return request({
        url: '/wms/supplier/supplierBadList',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 查询供应商详细
export function getSupplier(id) {
    return request({
        url: '/wms/supplier/' + id,
        method: 'get',
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function getSupplierByZrId(zrId) {
    return request({
        url: '/wms/supplier/access/' + zrId,
        method: 'get',
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 新增供应商
export function addSupplier(data) {
    return request({
        url: '/system/supplier',
        method: 'post',
        data: data,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 修改供应商
export function updateSupplier(data) {
    return request({
        url: '/wms/supplier',
        method: 'put',
        data: data,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 删除供应商
export function delSupplier(hid) {
    return request({
        url: '/system/supplier/' + hid,
        method: 'delete',
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 查询非招标
export function listNoBid(query) {
    return request({
        url: '/wms/supplier/noBidList',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function getNobid(query) {
    return request({
        url: '/wms/supplier/fromCode',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function listDev(query) {
    return request({
        url: '/wms/supplier/listDev',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function listSubmission(query) {
    return request({
        url: '/wms/supplier/listSubmission',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function onlyHCreditCode(query) {
    return request({
        url: '/wms/supplier/onlyHCreditCode',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 新增供应商
export function registerSupplier(data) {
    return request({
        url: '/wms/supplier/registerSupplier',
        method: 'post',
        data: data,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function codeyz(query){
    return request({
        url: '/app/loginuser/codeyz',
        method: 'post',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

// 查询供应商详细
export function getTenderInfo(id) {
    return request({
        url: '/wms/supplier/tender/' + id,
        method: 'get',
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function listTender(query) {
    return request({
        url: '/wms/supplier/listTender',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}