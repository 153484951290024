<template>
  <div class="box">
    <el-tabs @tab-click="handleClick" v-model="activeName">
      <el-tab-pane label="招标公示" class="font" name="first">
        <!--招标公示-->
        <el-table
            :data="tenderList"
            stripe
            style="width: 100%"
            :default-sort = "{prop: 'uUpdateTime', order: 'descending'}">
          <el-table-column
              align="center"
              type="index"
              label="编号"
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              prop="sName"
              label="公告标题"
              width="500">
          </el-table-column>
          <el-table-column
              align="center"
              label="剩余天数">
            <template slot-scope="scope">
              <!-- 使用计算属性来计算剩余天数 -->
              <span style="color: red;font-size: 16px;font-weight: bold;">{{
                  calculateRemainingDays(scope.row.sEndTime)
                }}</span>天
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="fjStatus"
              label="操作"
              width="250">
            <template slot-scope="scope">
              <router-link :to="'/detail?sid='+scope.row.sid+'&type=bidNotice'">
                <el-button size="small" type="primary">我要投标</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="updateTime"
              label="公示时间" sortable>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="中标结果" class="font" name="second">
        <!--中标结果-->
        <el-table
            :data="tableData"
            stripe
            style="width: 100%">
          <el-table-column
              align="center"
              type="index"
              label="编号"
              width="180">
          </el-table-column>
          <el-table-column
              align="center"
              prop="sName"
              label="项目名称"
              width="500">
            <template slot-scope="scope">
              <span>{{scope.row.sName || '——'}}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="fjStatus"
              label="招标单位">
            <template slot-scope="scope">
              <span>{{scope.row.sUnit || '——'}}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="fjStatus"
              label="操作"
              width="250">
            <template slot-scope="scope">
              <router-link :to="'/detail?uid='+scope.row.uid+'&type=winning&sid='+scope.row.sid">
                <el-button size="small" type="primary">查看详情</el-button>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="结束时间">
            <template slot-scope="scope">
              <span>{{scope.row.updateTime || '——'}}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {getList, findAllByEndTime, listNotice} from '@/api/home'

export default {
  name: 'MoreList',
  data() {
    return {
      tenderList: [],
      tableData: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderNum: null,
        uid: null,
        sid: null,
        uTitle: null,
        uProject: null,
        uMoney: null,
        uGetTime: null,
        uAcceptTime: null,
        uEndTime: null,
        uKaiTime: null,
        fjAnnex: null,
        sProjectState: null,
        fjRemark: null,
        uUpdateTime: null,
      },
      activeName: "first",
    }
  },
  created() {
    this.getListNotice();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
      if("first" === this.activeName){
        this.getListNotice();
      }else if("second" === this.activeName ){
        this.findAllByEnd();
      }
    },
    //查询招标公示
    Info() {
      this.getListNotice();
      this.findAllByEnd();
    },
    calculateRemainingDays(uEndTime) {
      // 将字符串转换为日期对象
      const updateTime = new Date(uEndTime);
      // 计算当前日期与公示时间的差值，并返回剩余天数
      return Math.ceil(Math.abs(Date.now() - updateTime) / (1000 * 60 * 60 * 24));
    },
    findAllByEnd(){
      //终止
      findAllByEndTime().then(res => {
        this.tableData = res.data.rows;
      })
    },
    getListNotice(){
      this.queryParams.sProjectState = 5;//已发布状态
      listNotice(this.queryParams).then(res => {
        this.tenderList = res.data.rows;
        console.log(this.tenderList,"this.tenderList");
        this.total = res.total;
      })
    }
  }
}
</script>
<style>
.font {
  font-size: 16px;
}

.tenderInfo {
  font-size: 14px;
}
</style>
