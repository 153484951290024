import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "about" */ '../views/NoticeDetail.vue')
    },
    {
        path: '/regist',
        name: 'regist',
        component: () => import(/* webpackChunkName: "about" */ '../views/regist.vue')
    }
    ,
    {
        path: '/mine',
        name: 'mine',
        component: () => import(/* webpackChunkName: "about" */ '../views/mine.vue')
    }
    ,
    {
        path: '/quotation',
        name: 'quotation',
        component: () => import(/* webpackChunkName: "about" */ '../views/quotation.vue')
    }
    ,
    {
        path: '/details',
        name: 'details',
        component: () => import(/* webpackChunkName: "about" */ '../views/details.vue')
    },
    {
        path: '/homeView',
        name: 'homeView',
        component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
