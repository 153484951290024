<template>
  <div class="box">
    <el-row>
      <el-col >
        <div class="bg-purple">
          <!-- <div class="box-bottom">
            <div class="shixin">招标公告</div>
           <el-tag type="warning" class="right" effect="dark" size="medium">更多</el-tag>
          </div> -->
		  <div class="sort current">
			  <div class="sort-hd clearfix">
				  <span class="category-all">全部品类</span>
			  </div>
			  <div class="sort-bd">
				  <div class="sort-mod clearfix">
					  <span class="sort-first">招标状态：</span>
					  <div class="sort-list">
              <el-radio-group v-model="radio" class="sort-items">
                <el-radio-button class="sort-item"  label="全部"    @click.native="goStatus('')"></el-radio-button>
                <el-radio-button class="sort-item"  label="招标公告" @click.native="goStatus('1')"></el-radio-button>
                <el-radio-button class="sort-item"  label="变更公告" @click.native="goStatus('2')"></el-radio-button>
                <el-radio-button class="sort-item"  label="中标公告" @click.native="goStatus('3')"></el-radio-button>
              </el-radio-group>
					  </div>
				  </div>
			  </div>
		  </div>
          <!--今日开标-->
          <el-card v-for="(notice, index) in notices" :key="index" class="tender-notice">
            <div class="item-top clearfix">
              <div class="l item-top-left">{{ tenderNotice(notice) }}</div>
            </div>
            <a href="#" class="tender-name" style="overflow-wrap: break-word;">{{notice.uTitle}}</a>
            <el-row class="tender-notice-content">
              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-news margin-right-5"></i>
                  <span class="tender-label">招标编号：</span>
                  <span class="tender-skip" >{{ notice.bidTender.sCode }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-menu margin-right-5"></i>
                  <span class="tender-label l">项目名称：</span>
                  <span class="tender-skip" >{{ notice.bidTender.sName }}</span>
                </div>
              </el-col>

              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-office-building margin-right-5"></i>
                  <span class="tender-label">发布企业：</span>
                  <span class="tender-skip" >{{ notice.bidTender.sUnit }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-date margin-right-5"></i>
                  <span class="tender-label">发布日期：</span>
                  <span class="tender-skip" >{{ parseTime(notice.uKaiTime, '{y}-{m}-{d}')  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-alarm-clock margin-right-5"></i>
                  <span class="tender-label">截止日期：</span>
                  <span class="tender-skip" >{{ parseTime(notice.uEndTime, '{y}-{m}-{d}')  }}</span>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="tender-info">
                  <i class="el-icon-data-line margin-right-5" v-if="notice.uMoney > 0"></i>
                  <span class="tender-label" v-if="notice.uMoney > 0">{{totalPriceName(notice)}}</span>
                  <span v-if="notice.uMoney > 0">
                    <span class="tender-skip" style="color: #2f54eb" >{{ notice.uMoney }}</span>
                    万元
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-row class="tender-bom">
              <el-col :span="8" class="flex-container">
                <div class="tender-bom-time" style="color: red">{{remainingTimeStatus(notice)}}</div>
                <el-button type="primary" round size="small" @click="showDetails(notice)">查看详情</el-button>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-col>
    </el-row>
	<el-row style="text-align: center">
		<pagination
		    v-show="total>0"
		    :total="total"
		    :page.sync="queryParams.pageNum"
		    :limit.sync="queryParams.pageSize"
		    @pagination="listNotice"
		    class="pagination-position"
		/>
	</el-row>
  </div>
</template>
<script>
import {listTender} from "@/api/system/supplier";
import Pagination from "@/components/Pagination/index.vue";
import {listNotice} from "@/api/system/notice";
export default {
  name: 'MoreList',
  components: {Pagination},
  data() {
    return {
      notices:  [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 12,
        orderNum: null,
        uid: null,
        sid: null,
        uTitle: null,
        uProject: null,
        uMoney: null,
        uGetTime: null,
        uAcceptTime: null,
        uEndTime: null,
        uKaiTime: null,
        fjAnnex: null,
        fjStatus: null,
        fjRemark: null,
        uUpdateTime: null,
        sProjectStates: null,
        fjNoStatus:'1',
        type:'1',
      },
      total:0,
      radio:'招标公告',
    }
  },
  created() {
    this.info();
    
    this.listNotice();
  },
  computed: {

  },
  methods:{

    listNotice() {
      listNotice(this.queryParams).then(res => {
        console.log(res)
        this.notices = res.data.rows;
        this.total = res.data.total;
      })
    },
    info(){
      //已发布状态
      this.queryParams.sProjectStates = "2,3";
    },
    showDetails(notice) {
      let type = 'bidNotice'
      if (notice.type == '2'){
        type = 'winning'
      } else if  (notice.type == '3'){
        type = 'theBid'
      }
      // 处理查看详情逻辑
      this.$router.push({ name: 'detail', query: { uid: notice.uid || 1 ,type:type} });
    },
    remainingTimeStatus(row){
      if (!this.isNotNull(row.uEndTime)){
        return '已过期';
      }
      if (row.type === '3'){
        return '';
      }
      const now = new Date();
      const uEndTime = new Date(row.uEndTime);
      const diff = uEndTime - now;

      if (diff <= 0) {
        // 如果已经过期，返回相应的字符串
        return '已过期';
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return `${days}天${hours}小时${minutes}分钟`;
    },
    // 日期格式化
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },
    isNotNull(row) {
      return row!== undefined && row !== null && row !== '';
    },
    //父组件调用的查询
    conditionalQuery(params){
      this.queryParams.uTitle = params.uTitle;
      this.listNotice();
    },
    //招标状态
    goStatus(row){
      if (row === '1'){
        this.queryParams.type = '1';
      } else if (row === '2'){
        this.queryParams.type = '2';
      } else if (row === '3'){
        this.queryParams.type = '3';
      } else {
        this.queryParams.type = '';
      }
      this.listNotice();
    },
    //招标公告
    tenderNotice(row){
      let type = row.type
      if (!this.isNotNull(type)){
        return '招标公告';
      }
      if (type === '1'){
        return '招标公告';
      } else if (type === '2'){
        return '变更公告';
      } else if (type === '3'){
        return '中标公告';
      } else {
        return '招标公告';
      }
    },
    totalPriceName(row){
      let type = row.type
      if (!this.isNotNull(type)){
        return '预估总价：';
      }
      if (type === '1'){
        return '预估总价：';
      } else if (type === '2'){
        return '预估总价：';
      } else if (type === '3'){
        return '中标金额：';
      } else {
        return '预估总价：';
      }
    }
  }
}
</script>
<style>
.box {
  width: 1400px;
  margin: 20px auto;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.grid-content {
  height: auto;
  padding-bottom: 10px;
  width: 800px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

.bg-purple-light {
  width: 570px;
}

.box-bottom {
  border-bottom: 2px solid #409EFF;
  margin-bottom: 10px;
}

.shixin {
  width: 100px;
  height: 56px;
  background-color: #409EFF;
  color: #ffffff;
  text-align: center;
  line-height: 56px;
}

.right {
  float: right;
  position: relative;
  top: -40px;
  right: 20px;
}

.bg-info {
  width: 310px;
  height: 30px;
//border: 1px solid #000; margin-left: 10px; text-align: center;
  line-height: 30px;
  font-size: 14px;
}

.tender-notices {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #f5f5f5;
}

.tender-notice {
  /* 每列占满约33%的可用宽度 */
  /*flex: 0 0 calc(33% - 20px); */
  /*<2> margin: 20px;width: 22%;*/
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  width: 23%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  float: left;
}

.tender-notice-header {
  font-weight: bold;
  margin-bottom: 15px;
}

.tender-notice-content {
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tender-details-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* 以下是模拟模态框的样式，实际中可能需要额外的组件或库 */
.modal {
  /* 模态框的样式，这里只是占位符 */
  display: none; /* 初始隐藏 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 其他样式... */
}

.modal-content {
  /* 模态框内容的样式，这里只是占位符 */
  /* 其他样式... */
}
.el-col {

}
.el-col .el-col-8 {
  width: 100% ;
  font-size: 14px;
}
.item-top-left {
  background: #2f54eb;
  background: linear-gradient(to right, #2f54eb, #bb99ff);
  background: -moz-linear-gradient(left, #2f54eb, #bb99ff);
}
.tender-name {
  display: block;
  height: 48px;
  padding-bottom: 11px;
  line-height: 27px;
  font-size: 16px;
  color: #333;
  overflow: hidden;
  border-bottom: 1px solid #e5e8f0;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-top-left {
  margin: -30px 0 6px -39px;
  width: 60px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  border-radius: 0 11px 11px 0;
}
.l {
  float: left !important;
}
a {
  text-decoration: none;
}
.clearfix {
  display: block;
}

.truncate-text {
  /* 设定一个最大高度以允许多行文本显示 */
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 控制显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  /* 可能还需要设置一个宽度，但这里假设 <el-col> 已经处理了宽度 */
}
.col-with-margin {
  /* 增加内边距，可以根据需要调整 */
  padding: 10px;
  /* 或者增加外边距 */
  /* margin: 10px; */
}
.margin-right-5 {
  margin-right: 5px; /* 图标与文本之间的间距 */
}
.tender-info {
  /* 使用Flexbox布局 */
  display: flex;
  /* 垂直居中文本和图标 */
  align-items: center;
  margin-bottom: 9px;
  height: 25px;
}
.tender-label {
  min-width: 70px; /* 设置一个固定的或最小的宽度 */
  padding-left: 0px;
  line-height: 0px;
  color: #838faa;
}
.tender-skip {
  flex: 1; /* 占据剩余的空间 */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tender-bom {
  /* position: absolute; */
  display: flex;
  /*margin: 0 22px;*/
  line-height: 45px;
  border-top: 1px solid #e5e8f0;
}
.tender-bom-time {
  /*min-width: 70px;*/
}
.flex-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center; /* 使内容垂直居中 */
}
/* .pagination-position {
  position: relative;
  top : 1212px;
  left: -440px;
} */

.sort {
    margin-bottom: 20px;
}

.sort-hd {
    height: 47px;
    border-bottom: 1px solid #eaebed;
    line-height: 47px;
    background: #fff;
}

.sort.current .sort-mod:nth-child(n+4) {
    display: block;
}

.sort.current .sort-all .open {
    display: none;
}

.sort.current .sort-all .close {
    display: inline;
}

.industry-serach {
    margin: 9px 20px 0 0;
}

.sort-bd {
    line-height: 38px;
    font-size: 16px;
    padding: 0 20px;
    background: #fff;
}

.sort-first {
    float: left;
    width: 90px;
    font-family: "SourceHanSansCN-Medium";
}

.sort-mod {
    border-bottom: 1px dashed #eaebed;
    padding: 2px 0;
}

.sort-mod:last-child {
    border-bottom: 0;
}

.sort-mod.active .sort-list {
    height: auto;
}

.sort-mod.active .close {
    display: inline;
}

.sort-mod.active .open {
    display: none;
}

.sort-mod:nth-child(n+4) {
    display: none;
}

.sort-btn {
    float: right;
    color: #2f54eb;
}

.btn-txt {
    color: #2f54eb;
}

.sort-list {
    float: left;
    width: calc(100% - 186px);
    height: 40px;
    overflow: hidden;
}

.sort-items {
    font-size: 0;
}

.sort-item {
    display: inline-block;
    box-sizing: border-box;
    margin-right: 10px;
    position: relative;
}

.sort-item a {
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 28px;
    margin: 5px 0;
    padding: 0 12px;
}

.sort-close {
    position: absolute;
    width: 13px;
    height: 13px;
    /* background: url("../images/tender/close_icon.png") no-repeat; */
    top: 1px;
    right: -1px;
    display: none;
    cursor: pointer;
}

.sort-item.active .sort-close {
    display: block;
}

.sort-item.active a {
    background: #f3f5f8;
    color: #2f54eb;
}

.sort-item a:hover {
    color: #2f54eb;
}

.sort-all {
    display: block;
    height: 40px;
    line-height: 40px;
    background: #fff;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    color: #2f54eb;
}

.sort-more {
    float: right;
    margin-right: 10px;
    position: relative;
}

.category-all {
    float: left;
    cursor: pointer;
    width: 76px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    color: #fff;
    padding-left: 30px;
    background: url("../../../public/images/menu_icon.png") 11px center no-repeat #2f54eb;
    margin: 9px 0 0 16px;
}

</style>
