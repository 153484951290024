<template>
  <div class="type-nav">
    <div class="container">
      <nav class="nav">
      <!--  <el-button type="primary" class="set-up" @click="indexHome">
          <el-link type="primary" href="/">首页</el-link>
        </el-button> -->
        <el-button type="primary" class="set-up" @click="tenderInfo">
          <el-link type="primary">招标信息</el-link>
        </el-button>
        <el-button type="primary" @click="dian" class="set-up">
          <el-link type="primary">供应商中心</el-link>
        </el-button>
      </nav>
      <el-dialog
          :visible.sync="dialogVisible"
          width="650px">
        <Login @update:visible="handleDialogVisibleChange"></Login>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Login from '@/components/login/Login.vue'

export default {
  name: 'TypeNav',
  components: {
    Login
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    dian() {
      if (sessionStorage.getItem("token") == null) {
        this.dialogVisible = true
      } else {
        this.$router.push("/mine")
      }
      this.$emit('alter:value', true);
    },
    handleDialogVisibleChange(newValue) {
      this.dialogVisible = newValue;
    },
    indexHome(){
      this.$emit('alter:value', true);
    },
    tenderInfo(){
      // this.$emit('alter:value', false);
	   this.$router.push("/homeView")
    }
  }
}
</script>

<style lang="less" scoped>
.type-nav {
  border-bottom: 2px solid #fff;

  .container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    position: relative;
    background-color: #409EFF;
    box-shadow: 0 2px 12px 0 #3a8ee6d6;
    justify-content: space-between;
    align-items: center;
    a {
      height: 36px;
      margin: 0 20px;
      line-height: 45px;
      font-size: 16px;
      color: #FFF;
    }
  }
}
.set-up {
  padding: 12px 286px;
}
</style>
