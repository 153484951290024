import service from "@/utils/request";

export function getList(query) {
    return service({
        url: '/bidding/notice/list',
        method: 'get',
        params: query
    })
}

export function findAllByEndTime() {
    return service({
        url: '/wms/supplier/find',
        method: 'post',
        baseURL: process.env.VUE_APP_BASE_API
    })
}

export function findTwoInfo(uid) {
    return service({
        url: '/wms/notice/findTwoInfo/'+uid,
        method: 'get',
    })
}

export function downloadZip(data) {
    return service({
        url: '/bidding/tenderFile/downloadZip',
        method: 'post',
       data: data
    })
}

//查询招标公告
export function listNotice(query) {
    return service({
        url: '/wms/supplier/listNotice',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

//查询中标候选人
export function getCandidateList(query) {
    return service({
        url: '/wms/supplier/getCandidateList',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

//
export function listBidding(query) {
    return service({
        url: '/wms/supplier/listBidding',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}

//查询中标公告
export function getWinningList(query) {
    return service({
        url: '/wms/supplier/getWinningList',
        method: 'get',
        params: query,
        baseURL: process.env.VUE_APP_BASE_API
    })
}